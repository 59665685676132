/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location, prevLocation }) => {
  setTimeout(() => {
    const { hash } = location

    const id = hash && hash.replace('#', '')
    const element = document.getElementById(id)

    if (element) {
      element && element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      })
    }
  }, 1500)

}